import { Injectable } from '@angular/core';
// import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { ROLES } from 'src/app/app.const';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Observable } from 'rxjs';
import { AppService } from '../services/app.service';
import { LocalStorageService, STORAGE_CONSTANTS } from '../services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizeGuard  {

  constructor(
    private authService: AuthService,
    private storageService: LocalStorageService,
    private appService: AppService
  ) { }

  canActivate(
    {routeConfig}: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const routeData: any = routeConfig?.data || {};
      if(this.authService.isLoggedIn()){
        return this.authService.me()
          .then( ({ roles, number_auth, password_change_required, is_premium }: any) => {
            if (!number_auth) {
              return this.setNextRouteAndRedirect(`/verify-your-phone`);
            }
            if(password_change_required) {
              return this.setNextRouteAndRedirect(`/reset-password`);
            }
            // if(!is_premium){
            //   return this.setNextRouteAndRedirect(`/general/billing-info`);
            // }
            // All routes are accessible to SUPER_USER role
            if(roles.includes(ROLES.SUPER_USER)) {
              return true;
            }
            const validRoles =  routeData.roles.filter((role: string[]) => {
              return roles.includes(role);
            });
            if (validRoles.length) {
              return true;
            } else {
              this.redirectToHomeOrSkip();
              return false;
            }
          })
          .catch(error => {
            this.redirectToHomeOrSkip();
            return false;
          });
      } else {
        this.redirectUnauthorizedToLogin(routeData?.redirectUnauthorizedTo);
        return false;
      }
  }

  redirectToHomeOrSkip(){
    const href = location.href;
    if(href.includes("/signup") || href.includes("/signin")){ 
      // No need to redirect to home if on signup & signin
    } else {
      this.appService.navigateWithPreserve(`/home`);
    }
  }

  redirectUnauthorizedToLogin(url='/signin') {
    const href = location.href;
    // set flag to redirect on login if not loggedin & no url set already
    if(!localStorage.getItem(STORAGE_CONSTANTS.QATCH_COOKIE) && !localStorage.getItem(STORAGE_CONSTANTS.REDIRECT_TO_URL_ON_SIGNIN)){
       if(href.includes("/signup") || href.includes("/signin")){ // Do not store redirect url when request is for Signup/Signin
          // No need to setup REDIRECT_URL for signup & signin
       } else {
          localStorage.setItem(STORAGE_CONSTANTS.REDIRECT_TO_URL_ON_SIGNIN, href.replace(location.origin, ''));
       }
    }
    const redirectUrl = `${url}${location.search}`;
    this.appService.navigateByURL(redirectUrl);
  }

  setNextRouteAndRedirect(redirectTo: string) {
    this.storageService.set(STORAGE_CONSTANTS.NEXT_ROUTE, this.authService.getNextRoute());
    this.appService.navigateWithPreserve(redirectTo);
    return false;
  }
}
