export class TextUtil {

    public static isFilledArray = (arr) => {
        return arr && Array.isArray(arr) && arr.length>0; // return true if array and has length > 0
      }
      
      public static hasAtleastOne = (arr1, arr2) => {
        return arr1.some(v => arr2.includes(v));
      }
      
      public static isNullOrBlank = (obj) => {
        return obj === null || obj === undefined || obj.length===0
      }
      
      public static nonNull = (obj) => {
        return !this.isNullOrBlank(obj);
      }
}
