import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppService } from './shared/services/app.service';
import * as AOS from 'aos';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    chatDashboard = false;
    constructor(
        private location: Location,
        protected deviceService: DeviceDetectorService,
        private appservice: AppService,
        private route: ActivatedRoute
    ) {
        this.chatDashboard = window.location.pathname.startsWith('/chat-view');
        this.deviceService.isMobile = () => {
            return (innerWidth < 980);
        };
        this.deviceService.isDesktop = () => {
            return (innerWidth > 980);
        }
        this.route.queryParams.subscribe((params: any) => {
            if(params.utm_campaign) {
                this.getAppConfig(params);
            }
        });
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        const isDesktop = this.deviceService.isDesktop(), bodyTag = document.getElementsByTagName('body')[0];
        bodyTag.className = `${isDesktop ? 'desktop-device' : 'mobile-device'}`;
    }

    ngOnInit() {
        const ua = window.navigator.userAgent;
        const trident = ua.indexOf('Trident/');
        let version = null;
        if (trident > 0) {
            // IE 11 => return version number
            const rv = ua.indexOf('rv:');
            version = parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }
        if (version) {
            const body = document.getElementsByTagName('body')[0];
            if(body) body.classList.add('ie-background');
        }
        this.onResize();
        AOS.init({
            duration: 1500,
            once: false, // whether animation should happen only once - while scrolling down
            mirror: true, // whether elements should animate out while scrolling past them
		});
    }

    hideFooter() {
        let title = this.location.prepareExternalUrl(this.location.path());
        title = title.slice(1);
        return ['sign-up', 'nucleoicons'].includes(title);
    }

    getRoute() {
        return this.location.prepareExternalUrl(this.location.path()).slice(1);
    }

    getAppConfig(params) {
        this.appservice.getAppConfig(params).then(resp => {});
    }
}