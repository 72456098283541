import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NouisliderModule } from 'ng2-nouislider';
import { JwBootstrapSwitchNg2Module } from '@servoy/jw-bootstrap-switch-ng2';
import { RouterModule } from '@angular/router';
import { AngularMultiSelectModule } from 'ngb-dropdown';
import { QuestionControlService } from './questions/service/question-control.service';
import { TagInputModule } from 'ngx-chips';
import { UiKitModule } from 'projects/ui-kit/src/public-api';
import { ChatModule } from '../shared/modules/chat/chat.module';
import { SHARED_COMPONENTS } from '.';
import { HssQueryBuilderModule } from 'hss-query-builder';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { CarouselModule } from 'primeng/carousel';
import { UikitErrorComponent } from './uikit-error/uikit-error.component';
import { QButtonComponent } from './q-button/q-button.component';
import { SkeletonModule } from 'primeng/skeleton';
import { LoaderComponent } from './loader/loader.component';
import { ConfirmModalContent } from './modal/confirm.modal';
import { QModalHeaderComponent } from './modal/q-modal-header.component';
import { InputPanelComponent } from './input-panel/input-panel.component';
import { QEditorComponent } from './q-editor/q-editor.component';
import { DropdownModule } from 'primeng/dropdown';
import { ChipsModule } from 'primeng/chips';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        NouisliderModule,
        RouterModule,
        JwBootstrapSwitchNg2Module,
        AngularMultiSelectModule,
        TagInputModule,
        UiKitModule,
        ChatModule,
        ButtonModule,
        HssQueryBuilderModule,
        CheckboxModule,
        CarouselModule,
        UikitErrorComponent,
        QButtonComponent,
        SkeletonModule,
        LoaderComponent,
        ConfirmModalContent,
        QModalHeaderComponent,
        InputPanelComponent,
        QEditorComponent,
        DropdownModule,
        ChipsModule
    ],
    declarations: SHARED_COMPONENTS,
    exports: [
        ...SHARED_COMPONENTS,
        LoaderComponent,
        QButtonComponent,
        UikitErrorComponent,
        ConfirmModalContent,
        QModalHeaderComponent,
        InputPanelComponent,
        QEditorComponent,
    ],
    providers: [ QuestionControlService ]
})
export class ComponentsModule { }
