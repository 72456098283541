import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, Params } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { lastValueFrom, Observable } from 'rxjs';
import { LocalStorageService, STORAGE_CONSTANTS } from '../services/storage.service';
import { ToastService } from '../services/toast.service';
import { Logger } from '../utils/log-util';

@Injectable({
  providedIn: 'root'
})
export class RootGuard  {

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastService: ToastService,
    private storageService: LocalStorageService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const magiclinkToken = route.queryParams['magic-token'];
    if (magiclinkToken) {
      return this.loginByMagiclinkToken(magiclinkToken, route.queryParams);
    } else {
      return true;
    }
  }

  async loginByMagiclinkToken(magicToken: string, params: Params) {
    lastValueFrom(this.authService.createCustomToken({magicToken}))
    .then(async (resp) => {
      await this.authService.logOut();
      this.authService.signInWithCustomToken(resp).then(resp => {
        this.storageService.set(STORAGE_CONSTANTS.NEXT_ROUTE, this.getCurrentRouteWithoutMagictoken(params));
        this.storageService.storeInAppCache(STORAGE_CONSTANTS.JUST_LOGGED_IN_EVENT, 'true');
      }).catch((error) => {
        Logger.debugError(error);
      });
    })
    .catch((error) => {
      Logger.debugError(error);
      this.toastService.error(error);
    });
    return true;
  }

  getCurrentRouteWithoutMagictoken(params: Params) {
    let route = location.href.replace(location.origin, '')
    route = route.slice(0, route.indexOf('?'));
    const filteredParams = [];
    for (const [k, v] of Object.entries(params)) {
      if(k !== 'magic-token'){
        filteredParams.push(`${k}=${v}`);
      }
    }  
    return `${route}?${filteredParams.join('&')}`;
  }
}
