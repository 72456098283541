import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { AuthenticateViaPhoneComponent } from './authenticate-using-phone/authenticate-using-phone.component';
import { CreateAccountComponent } from './create-your-account/create-account.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SigninComponent } from './signin/signin.component';
import { SignoutComponent } from './signout/signout.component';
import { SignupQuestionaireComponent } from './signup-with-questionaire/signup.component';
import { VerifyEmailAndMobileComponent } from './verify-email-mobile/verify-email-mobile.component';
import { VerifyPhoneComponent } from './verify-phone/verify-phone.component';

const COMPONENTS = [
    SignupQuestionaireComponent,
    SigninComponent,
    SignoutComponent,
    VerifyEmailAndMobileComponent,
    CreateAccountComponent,
    AuthenticateViaPhoneComponent,
    ResetPasswordComponent,
    VerifyPhoneComponent
]

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        ...COMPONENTS
    ],
    exports: [
        ...COMPONENTS
    ]
})
export class AuthModule { }
