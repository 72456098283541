import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { DeviceDetectorService } from '../../services/device-detector.service';

export interface QSliderConfig {
  basePath?: string;
  numVisible?: number;
  numScroll?: number;
  circular?: boolean;
  showIndicators?: boolean;
  autoplayInterval?: number;
  showNavigators?: boolean;
  responsiveOptions?: any[]
}

@Component({
  selector: 'lib-q-slider',
  templateUrl: './q-slider.component.html',
  styleUrls: ['./q-slider.component.scss']
})
export class QSliderComponent implements OnInit {
  @Input() config: QSliderConfig = {};
  @Input() page = 0;
  @Output() pageChange = new EventEmitter<number>();
  _config: any = {
    basePath: '',
    numVisible: 1,
    numScroll: 1,
    circular: false,
    showIndicators: false,
    autoplayInterval: 0,
    showNavigators: true,
    showIndexes: false
  };
  @Input() sItems: any[] = [];
  @Input() itemTemplate: TemplateRef<any>;
  data = {
    DESKTOP: 4,
    MOBILE: 1,
    TAB:2
  };
  isMobile = false;
  constructor(private deviceDetector: DeviceDetectorService) {
    this.setNumVisible();
    this.deviceDetector.device.subscribe(this.setNumVisible);
  }

  ngOnInit(): void {
    this._config = {
      ...this._config,
      ...this.config
    };
    this.sItems = this.sItems.map( (i, indx) => {
      i.id = indx + 1;
      return i;
    })
  }

  setNumVisible() {
    this.isMobile = this.deviceDetector.isMobile;
    this._config.numVisible = this.data[this.deviceDetector.sDevice];
  }

  onPageChange(event: any)  {
    this.page = event.page;
    this.pageChange.emit(this.page);
  }

}
