<div class="non-transparent-header bg-pink">
    <div class="container">
        <div class="row">
            <div class="mx-auto">
                <div class="author text-center w-100 vmt-5 q-page-header" *ngIf="isDesktop">
                    <h1 class="title mt-0 q-page-header-title">Your Style, Your Choice</h1>
                    <span class="underline-text-dark w-300px"></span>
                </div>
                <div class="vmy-4 q-page-container">
                    <app-loader [visible]="ploading"></app-loader>

                    <form *ngIf="onboardingQuizForm" class="register-form mx-auto" [formGroup]="onboardingQuizForm"
                        [class.vmt-4]="isDesktop" (ngSubmit)="updateOnboardingQuizAnswers()">

                        <label class="label">Are you interested in any of these types of brands in
                            particular?</label>
                        <div class="bg-transparent input-group form-group-no-border ">
                            <div class="row w-100 chips">
                                <div *ngFor="let btype of brandTypes" (click)="toggle(btype)" class="col">
                                    <div class="chip-btn {{btype.id}}" [ngClass]="{'active': btype.checked}">
                                        {{btype.name}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <label class="label vmt-3">Which clothing brands do you typically shop?</label>
                        <div class="bg-transparent input-group form-group-no-border w-100" *ngIf="!brandLoading; else loader">
                            <div class="row row-cols-2 row-cols-md-3 g-2 w-100 chips">
                                <p-carousel class="w-100" #pCarouselRef [value]="brands" [circular]="false" [class.mobile]="isMobile"
                                [showIndicators]="false"
                                [numVisible]="brandsVisible" [numScroll]="1"
                                [showNavigators]="true" effect="fade">
                                    <ng-template let-brand pTemplate="item">
                                        <div class="col vp-3">
                                            <div class="chip-btn-img" (click)="toggle(brand)" *ngIf="brand.image_url">
                                                <i *ngIf="brand.checked" class="bi bi-heart-fill txt-danger quiz_style_choice_brand_click"
                                                    aria-hidden="true"></i>
                                                <img alt="{{brand.brand_name}}" src="{{brand.image_url}}" class="quiz_style_choice_brand_click">
                                            </div>
                                        </div>
                                    </ng-template>
                                </p-carousel>
                            </div>
                        </div>
                        <ng-template #loader>
                            <div class="text-center">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </div>
                        </ng-template>

                        <div *ngFor="let question of questions" class="form-row {{ remoteQuestionsMapping[question.question_id] }}">
                            <app-question [spacing]="'vmt-3'" [question]="question" [form]="onboardingQuizForm"
                                class="vpx-1 w-100" [uiTaggingKey]="'almost_done'">
                            </app-question>
                        </div>

                        <label class="label vmt-3">
                            What is your DOB? <span class="required">*</span>
                        </label>
                        <div class="input-group form-group-no-border">
                            <div class="datepicker w-100">
                                <input type="text" class="bg-default shadow vp-1 border-0  w-100"
                                    placeholder="Please Select" readonly ngbDatepicker #d="ngbDatepicker"
                                    (click)="d.toggle()" formControlName="dob" [minDate]="dobValidation.minDate" 
                                    [maxDate]="dobValidation.maxDate">
                            </div>
                        </div>
                        <uikit-error [submitted]="false" [controls]="controls" name="dob" placeholder="DOB"></uikit-error>

                        <label class="label vmt-3">
                            What is your email? <span class="required">*</span>
                        </label>
                        <div class="input-group form-group-no-border">
                            <div class="email w-100">
                                <input class="bg-default shadow vp-1 border-0 w-100"
                                    placeholder="Email" formControlName="email">
                            </div>
                        </div>
                        <uikit-error [submitted]="false" [controls]="controls" name="email" placeholder="Email"></uikit-error>

                        <div class="form-group vmt-3">
                            <label class="label">
                                Can we follow you on Instagram too? Drop your IG handle!
                            </label>
                            <div class="input-group form-group-no-border insta-gradient shadow">
                                <span class="input-suffix fs-24">@</span>
                                <input type="text" class="form-control bg-transparent txt-white fs-18 almost_done_instagram_box"
                                    formControlName="instagram_handle">
                            </div>
                            <small class="text-muted">
                                <br />We’ll follow you from the official <u>@joinqatch</u> Instagram & this will
                                give us a better sense of your style.
                                <br />Here’s a link to our privacy policy:
                                <a [routerLink]="['/privacy-policy']"
                                    class="text-dark almost_done_privacy_policy">https://joinqatch.com/privacy</a>
                            </small>
                        </div>

                        <div class="form-row justify-content-center vmt-3">
                            <button class="btn btn-yellow btn-move-right quiz_style_choice_back" (click)="previousPage()">
                                <!-- <i class="nc-icon nc-minimal-left"> </i> -->
                                Back
                            </button>
                            <button [disabled]="(onboardingQuizForm && !onboardingQuizForm.valid) || loading"
                                type="submit" class="btn btn-yellow btn-move-right vml-2 quiz_style_choice_next">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                    *ngIf="loading"></span>
                                Next
                                <!-- <i class="nc-icon nc-minimal-right"> </i> -->
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="top-right-img d-none d-md-block">
        <img src="./assets/img/organic/Shape-1.png" />
    </div>
    <div class="bottom-left-img">
        <img src="./assets/img/organic/Shape-2.png" />
    </div>
</div>