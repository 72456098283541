import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { AppService } from "./app.service";

export abstract class BaseService {
  baseUrl: string;
  dashboardBaseUrl: string;
  constructor(protected http: HttpClient, protected appService: AppService){
    this.baseUrl = `${this.appService.getAppBaseUrl()}`;
    this.dashboardBaseUrl = `${this.appService.getDashboardBaseUrl()}`;
  }

  getFilters(filters: any): Observable<any> {
    return this.http.post(`${this.apiUrl()}/filters`, {filters});
  }

  abstract apiUrl(): string;
}