import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export enum BUTTONS {
    CONFIRM = 'Confirm',
    DONE = 'Done',
    CANCEL = 'Cancel',
}

@Component({
    selector: 'q-modal-header',
    template: `<div class="modal-header-seprator d-flex justify-content-between align-items-center py-2 px-3">
               <h5 class="m-0">{{title}}</h5>
               <button type="button" class="close" (click)="close.emit($event)">&times;</button>
               </div>`,
               standalone: true,
               imports: [CommonModule]
})
export class QModalHeaderComponent {
    @Input() title: string;
    @Output() close = new EventEmitter<any>();

    constructor( public activeModal: NgbActiveModal ) { }

}