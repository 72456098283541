<ng-container *ngIf="!chatDashboard">
    <app-navbar class="app-navbar"></app-navbar>
    <div class="app-content">
        <router-outlet class="d-none"></router-outlet>
    </div>
    <app-footer *ngIf="!hideFooter()" class="app-footer"></app-footer>
    <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
</ng-container>

<ng-container *ngIf="chatDashboard">
    <router-outlet></router-outlet>
</ng-container>

<q-guided-tour></q-guided-tour>