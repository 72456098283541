import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentYear = new Date().getFullYear();
  socialAccountsLinks = [
    {title: 'Like us on Facebook', tag: 'home_facebook_icon', href: "https://www.facebook.com/JoinQatch/", icon: 'facebook' },
    {title: 'Follow us on Tiktok', tag: 'home_tiktok_icon', href: "https://www.tiktok.com/@joinqatch", icon: 'tiktok' },
    {title: 'Follow us on Instagram', tag: 'home_instagram_icon', href: "https://www.instagram.com/joinqatch/", icon: 'instagram' },
    {title: 'Follow us on LinkedIn', tag: 'home_linkedin_icon', href: "https://www.linkedin.com/company/joinqatch/", icon: 'linkedin' }
  ];
  qatchPagesLinks = [
    // { title: 'Trending', tag: 'home_trending_footer', path: '/trending'},
    { title: 'About Us', tag: 'home_about_us_footer', path: '/our-story'},
    { title: 'Contact Us', tag: 'home_contact_footer', path: '/contact-us'},
    { title: 'Sign In', tag: 'home_signin_footer', path: '/signin'},
    { title: 'Partnerships', tag: 'partnership_footer', path: '/partnerships'},
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
