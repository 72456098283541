import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators';

export class ViewTablePage {
  pageSize: number;
  totalElements = 0;
  totalPages = 0;
  pageNumber = 0;
  constructor(pageSize?: number) {
    this.pageSize = pageSize || 20;
  }

  reset() {
    this.totalElements = 0;
    this.totalPages = 0;
    this.pageNumber = 0;
  }
}

export class ViewTablePagedData {
  page?: ViewTablePage;
  data = [];
  http_resp?: any;
}

@Injectable({
  providedIn: 'root'
})
export class ViewTableService {

  constructor(
    private readonly http: HttpClient
  ) {}

  getData(url: string, page: any, sort: any = {}, filters: any = {}, dataKey: string = 'data'): Observable<ViewTablePagedData>  {
    let params = new HttpParams();
    if (sort.dir && sort.prop) {
      params = params.set('limit', page.pageSize).set('page', page.pageNumber).set('dir', sort.dir).set('prop', sort.prop);
    } else {
      params = params.set('limit', page.pageSize).set('page', page.pageNumber);
    }
    for (let filter in filters) {
      params = params.set(filter, filters[filter]);
    }
    if(url.startsWith('http')){
      return this.http.get(url, {params})
      .pipe(map((result: any) => {
        return this.getPagedData(page, result, dataKey)
      }));
    }else{
      return this.http.get(url, {params})
      .pipe(map((result: any) => {
        return this.getPagedData(page, result, dataKey)
      }));
    }
  }

  getPagedData(page: ViewTablePage, result: any, dataKey: string): ViewTablePagedData {
    const rData = result[dataKey];
    const count = result.count;
    const pagedData = new ViewTablePagedData();
    page.totalElements = count;
    page.totalPages = Math.ceil(page.totalElements / page.pageSize);
    pagedData.data = rData;
    pagedData.page = page;
    pagedData.http_resp = result;
    return pagedData;
  }

}
