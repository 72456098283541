import { Component, ElementRef, ViewChild } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { QatchComponent } from 'src/app/components/qatch.component';
import { AuthService } from '../../services/auth.service';
import { ChatAdapter } from './adapter/chat.adapter';
import { ChatService } from './adapter/chat.service';
import { ChatParticipantStatus, ChatParticipantType, HSSChatConfig, DEFAULT_CONFIG, MessageType, HssChatService, Theme, NgChat } from 'hss-chat';
import { BehaviorSubject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChatAdvanceFilterComponent } from './components/chat-advance-filter/chat-advance-filter.component';
import { FilterItem, FilterType } from '../filters/filters.component';
import { RemoteSearchDropdownComponent } from '../filters/filter-types/remote-search/dropdown.component';
import { AngularMultiSelect } from 'ngb-dropdown';

@Component({
  selector: 'qatch-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent extends QatchComponent {
  MessageType = MessageType;
  hssChatConfig: BehaviorSubject<HSSChatConfig> = new BehaviorSubject(DEFAULT_CONFIG);
  ChatParticipantType = ChatParticipantType;
  ChatParticipantStatus = ChatParticipantStatus;
  adapter: ChatAdapter;
  user: any;
  selectedUser: any;
  users: any[];
  filtersConfig = {
    singleSelection: true,
    resetOnOpen: true
  }
  remoteUserSearchConfig: FilterItem = {
      filterType: FilterType.remote_dropdown,
      data: [],
      filterKey: 'uid',
      filterId: 'user',
      dataKey: 'users',
      url: 'inventory/users',
      label: '',
      singleSelection: true,
      displayFunction: (row) => {
        const item = `UID ${row.uid} - ${row.first_name} ${row.last_name || ''}`;
        return item;
    }
  }
  chatWindowsettings = [
    { title: 'Refresh', id: 'refresh' },
    { title: 'Mark All Read', id: 'markread' }
  ];
  dashboardView = false;
  theme = Theme.Light;
  userNameFirstChar = '';
  chatParticipantStatus = ChatParticipantStatus;
  _hssChatConfig = {
    showAvailabilityStatus: true,
    preDefinedMessages: [],
    dashboard: {
      title: 'Messages',
      emptyState: {
        title: '',
        description: `Ready? Set. Chat! Let's jump right into things.`,
        image: 'assets/img/chat/chat_onboarding.svg'
      }
    },
    participantChat: {
      ...DEFAULT_CONFIG.participantChat,
      polling: false,
      preDefinedMessagesEnabled: true,
      pageSize: 15
    },
    participants: {
      ...DEFAULT_CONFIG.participants,
      polling: false,
      pageSize: 15
    }
  }
  @ViewChild(NgChat) ngChat: NgChat;
  @ViewChild(RemoteSearchDropdownComponent) remoteSearchDropdown: RemoteSearchDropdownComponent;
  constructor(
    protected override authService: AuthService,
    protected override deviceService: DeviceDetectorService,
    private chatService: ChatService,
    private hssChatService: HssChatService,
    private readonly ngbModal: NgbModal,

  ) {
    super(deviceService, authService);
    this.dashboardView = window.location.pathname.startsWith('/chat-view');
    this.authService.me().then(async (user: any) => {
      this.user = user;
      this.userNameFirstChar = user.first_name.charAt(0);
      this.adapter = new ChatAdapter(this, this.chatService, user);
      this.isAdmin = await this.authService.isAdmin();
      this._hssChatConfig.dashboard.emptyState.title = `Welcome, ${this.user?.first_name}`;
      this.hssChatConfig.next(this._hssChatConfig);
    });
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  public messageSeen(object) {
    this.adapter.onMessageSeen(object);
  }

  onSettingSelect({ value }, chatRef) {
    switch (value) {
      case 'refresh':
        this.ngChat.fetchMessageHistory({ window: chatRef.window, polling: true });
        break;
      case 'markread':
        this.adapter.onMessageSeen({
          destinataryId: chatRef.window.participant.id, messages: chatRef.window.messages, success: () => {
            const currentDate = new Date();
            const unseenMessages = [];
            chatRef.window.messages.forEach((msg) => {
              if (!msg.dateSeen) {
                unseenMessages.push(msg);
                msg.dateSeen = currentDate;
              }
            });
          }
        }, true);
        break;
    }
  }

  onDisplayNameClick(self) {
    window.open(`https://joinqatch.com/stylists/all-queues/${self.userId}`, '_blank');
  }

  chatParticipantStatusDescriptor(status: ChatParticipantStatus) {
    if (status === ChatParticipantStatus.Online) return 'Premium';
    else return 'Free';
  }

  openDashboardView() {
    window.open(`${location.origin}/chat-view`, '_blank');
  }

  openAdvanceChatFilterView() {
    const modalRef = this.ngbModal.open(ChatAdvanceFilterComponent, { centered: true, size: 'md' });
    modalRef.result.then((result) => {
      if (result) {
        this.hssChatService.refreshParticipants();
      }
    });
  }

  onFiltersValueChange({ value, dataKey }: any) {
    if (dataKey === this.remoteUserSearchConfig.dataKey && value?.length) {
      const {id, itemName} = value[0] || {};
      this.ngChat.onParticipantClickedFromFriendsList({
        participantType: ChatParticipantType.User,
        id,
        displayName: itemName,
        avatar: '',
        status: ChatParticipantStatus.Online
      });
    }
  }

  onNewChatClick(event) {
    setTimeout(() => {
      this.remoteSearchDropdown.ngbDropdown.openDropdown();
    },100)
  }
}
