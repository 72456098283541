import { Component, OnInit, Input, OnDestroy, ElementRef, Output, EventEmitter } from '@angular/core';
import { Subscription, finalize, fromEvent, takeWhile } from 'rxjs';
import { GroupByColumnPipe } from 'src/app/shared/pipes/group-by-column.pipe';
import { ToastService } from 'src/app/shared/services/toast.service';
import { IProductMeta } from '../uikit-product/uikit-product.component';
import { environment } from 'src/environments/environment';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'uikit-product-list',
  templateUrl: './uikit-product-list.component.html',
  styleUrls: ['./uikit-product-list.component.scss'],
  providers: [GroupByColumnPipe]
})
export class UikitProductListComponent implements OnInit, OnDestroy {
  products: any[] = [];
  windowScrollRef: Subscription;
  limit = 40;
  page = 1;
  ploading = true;
  hasMoreData = true;
  rawData = [];
  hasScrolled = false;
  @Input() fetchProducts: Function;
  @Input() productMeta: IProductMeta;
  @Input() resultKey = 'data';
  @Input() loadingText = 'loading...';
  @Input() displayEmptyMessage = 'No matching product found!!!';
  @Output() updateReaction = new EventEmitter();
  isDestroyed = false;
  productMapping = {};
  constructor( 
    private toastService: ToastService, 
    private groupByColumn: GroupByColumnPipe,
    private elmRef: ElementRef,
    private productService: ProductService
  ) { }

  ngOnInit(): void {
    this.windowScrollRef = fromEvent(window, 'scroll').subscribe(() => {
      this.hasScrolled = window.pageYOffset > 500;
      const productElms = this.elmRef.nativeElement.querySelector('.uikit-products-list')?.querySelectorAll('uikit-product');
      if(productElms?.length > 2) {
        const secLastProductLoaded = productElms.item(productElms.length - 3).querySelector('.Q_ITEM_LOADED');
        if(secLastProductLoaded && this.hasMoreData && !this.ploading) {
          this.loadLazyProducts(true);
        }
      }
    });
    this.loadLazyProducts(false);
  }

  loadLazyProducts(onScroll = false) {
    this.ploading = true;
    if(!onScroll) {
      this.page = 1;
      this.hasMoreData = true;
      this.rawData = [];
      this.products = [];
    }
    this.fetchProducts({limit: this.limit, page: this.page})
      .pipe(
        takeWhile(i => !this.isDestroyed),
        finalize(() => {
          this.ploading = false;
        })
      )
      .subscribe({
        next: res => {
          const data = res[this.resultKey];
          if(data?.length) {
            this.getProductsWithLoveCount(data.map(p => p.product_id));
          }
          this.rawData = [...this.rawData, ...data];
          this.runDuplicateTest();
          const transFormedProducts: any[] = this.groupByColumn.transform(res[this.resultKey] || []);
          if(this.page > 1) {
            transFormedProducts.forEach((column, index) => {
              column.forEach( product => {
                this.products[index].push(product);
              });
            });
          } else {
            this.products = transFormedProducts;
          }
          this.page = this.page + 1;
          this.hasMoreData = data?.length ? true : false;
        },
        error: (err) => {
          this.toastService.error(`Unable to load list. Please try again.`);
        },
      });
  }

  getProductsWithLoveCount(productIds) {
    this.productService.getProductsWithLoveCount(productIds)
    .pipe(takeWhile(() => !this.isDestroyed))
    .subscribe( (res: {product_id: string, loved: number}[]) => {
      this.productMapping = (res || []).reduce( (o, n) => {
        o[n.product_id] = n.loved;
        return o;
      }, this.productMapping);
    });
  }

  async runDuplicateTest() {
    if(!environment.production){
      var collectionsSize = this.rawData.length;
      var collections = this.rawData.map(coll => coll.product_id);
      var set = new Set(collections);
      var dset = new Set(collections);
      const duplicates = collections.filter(item => {
          if (dset.has(item)) {
              dset.delete(item);
          }else{
              return item;
          }
      });
      // console.log(collections.length, set.size);
      // console.log(duplicates);
      if(collectionsSize !== set.size){
        console.error('Duplicate check failed');
      }
    }
  }

  onUpdateReaction(product, columnIndex, rowIndex) {
    if(this.productMeta.page === 'my-qatch' && product.reaction_type === 'Disliked') {
      this.products[columnIndex].splice(rowIndex,1);
      const transFormedProducts: any[] = this.groupByColumn.transform(this.products.reduce((all, current) => {
        all = [...all, ...current];
        return all;
      }, []));
      this.products = transFormedProducts;
    }
    this.updateReaction.emit(this.page);
  }

  gotoTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });    
  }

  ngOnDestroy(): void {
    this.isDestroyed = true;
    this.windowScrollRef?.unsubscribe();
  }

}
