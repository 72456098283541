<div class="container-fluid non-transparent-header px-0">
    <div class="section p-0">
        <div class="row" [class.vh-100]="isDesktop">
            <div class="col-md-7" [ngClass]="{'my-auto pl-5': isDesktop}">
                <div class="author text-center q-page-header">
                    <ng-container *ngIf="isDesktop">
                        <h1 class="card-title q-page-header-title">Phone Number</h1>
                        <span class="w-30 underline-text-dark mb-4"></span>
                    </ng-container>

                    <label class="label mt-2">
                        What number should we text your recommendations to?
                    </label>
                    <small class="text-muted mt-3">
                        <br />You’ll receive 10 recommendations a week via text. We keep your personal
                        information safe.
                        <br />For more information, please visit our privacy policy at:
                        <a [routerLink]="['/privacy-policy']"
                            class="text-dark">https://joinqatch.com/privacy</a>
                        <br />Questions? Email us at <a
                            href="mailto:hello@qatchcode.com">hello@qatchcode.com!</a>
                    </small>
                </div>
                <div class="my-4">
                    <form *ngIf="verifyForm" class="register-form" [formGroup]="verifyForm" (ngSubmit)="submit()">
                        <div id="captcha-container"></div>

                        <div class="form-row mt-n1">
                            <div class="col-md-6">
                                <div class="form-group mb-0">
                                    <label class="label mt-4">Phone Number <span class="required">*</span></label>
                                    <div class="input-group form-group-no-border bg-default shadow">
                                        <span class="input-suffix">{{countryCode}}</span>
                                        <input type="text" class="form-control box-shadow-none" appPhoneMask
                                            formControlName="phone_number" placeholder="(xxx)xxx-xxxx">
                                    </div>
                                </div>
                                <div *ngIf="phone_number && !phone_number.valid && (phone_number.dirty || phone_number.touched)"
                                    class="alert alert-danger">
                                    <div *ngIf="phone_number.errors">
                                        Please enter valid phone number.
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group mb-0">
                                    <label for="inputState" class="label  mt-4">Verify Number <span class="required">*</span></label>
                                    <div class="input-group form-group-no-border bg-default shadow">
                                        <span class="input-suffix">{{countryCode}}</span>
                                        <input type="text" class="form-control box-shadow-none" appPhoneMask
                                            formControlName="c_phone" placeholder="(xxx)xxx-xxxx">
                                    </div>
                                </div>
                                <div *ngIf="phone_number?.valid && (!c_phone?.valid || c_phone?.value !== phone_number?.value) && (c_phone?.dirty || c_phone?.touched)"
                                    class="alert alert-danger">
                                    <div>
                                        Phone numbers don't match.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <label class="label mt-4">
                            What device type do you have? <span class="required">*</span>
                            <br />
                            <div class="txt-qatch-blue mt-2">
                                <a ngbTooltip="We optimize your experience based on your device type!" placement="right">Why do we ask?</a>
                            </div>
                        </label>
                        <div class="form-group-no-border w-100 justify-content-center text-center">
                            <div class="devices form-row">
                                <div class="col-md-3 mt-2 mx-1 px-3" (click)="toggle(device)"
                                    *ngFor="let device of devices">
                                    <div class="device-img">
                                        <img alt="{{device.title}}" src="{{device.image_url}}">
                                        <label class="label w-100 mx-auto" [ngClass]="{'selected': device.checked}">
                                            <i class="fa fa-check" aria-hidden="true" *ngIf="device.checked"></i>
                                            {{device.title}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <label class="label mt-4">
                            By signing up, you agree to our Terms of Service.
                        </label>
                        <small class="text-muted">
                            <br />By selecting “I agree,” you are opting-into Qatch’s text recommendation service.
                            <br />To take a break, just text us “PAUSE” and to cancel at any time,
                            text us “STOP.”
                            <br />Message and Data rates may apply.
                            <br />Read our Terms here: <a [routerLink]="['/terms']"
                                    class="text-dark">https://www.joinqatch.com/terms</a>
                        </small>
                        <div class="form-group-no-border">
                            <bSwitch [switch-on-color]="'danger'" [switch-off-color]="'danger'"
                                [switch-off-text]="'No'" [switch-on-text]="'Yes'" formControlName="agree_to_terms"
                                required>
                            </bSwitch>
                        </div>

                        <div class="form-row justify-content-center">
                            <button class="btn btn-dark btn-move-right mt-4" (click)="previousPage()">
                                <i class="nc-icon nc-minimal-left"> </i>
                                BACK
                            </button>
                            <button
                                [disabled]="(verifyForm && !verifyForm?.valid) || !agree_to_terms?.value || loading || ploading"
                                type="submit" class="btn btn-danger btn-move-right ms-2 mt-4">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                    *ngIf="loading || ploading"></span>
                                Verification Code
                                <i class="nc-icon nc-minimal-right"> </i>
                            </button>
                        </div>

                    </form>
                </div>
            </div>

            <div class="col-md-5 my-auto d-none d-md-block">
                <div class="cover-img" style="background-image: url('./assets/img/organic/qatchjuly2020-1679.jpg')">
                </div>
            </div>

        </div>
    </div>
</div>