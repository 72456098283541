import { HostListener, Injectable } from '@angular/core';
import { Subject } from 'rxjs';


enum DEVICE_TYPES {
  DESKTOP = 'DESKTOP',
  MOBILE = 'MOBILE',
  TAB = 'TAB'
}

@Injectable({
  providedIn: 'root'
})
export class DeviceDetectorService {
  isMobile = false;
  isDesktop = false;
  isTab = false;
  sDevice: DEVICE_TYPES = DEVICE_TYPES.DESKTOP;
  device = new Subject<string>();
  isAndroid = false;
  isiPhone = false;
  constructor() {
    this.setDeviceDetector();
    this.getMobileOperatingSystem();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.setDeviceDetector();
  }


  setDeviceDetector() {
    this.isMobile = false;
    this.isDesktop = false;
    this.isDesktop = false;
    if ((innerWidth < 600)) {
      this.isMobile = true;
      this.sDevice = DEVICE_TYPES.MOBILE;
      this.device.next(DEVICE_TYPES.MOBILE);
    } else if ((innerWidth < 1000)) {
      this.isTab = true;
      this.sDevice = DEVICE_TYPES.TAB;
      this.device.next(DEVICE_TYPES.TAB);
    } else {
      this.isDesktop = true;
      this.sDevice = DEVICE_TYPES.DESKTOP;
      this.device.next(DEVICE_TYPES.DESKTOP);
    }
  }

  getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor || window['opera'];
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      this.isAndroid = true; //"Windows Phone"
    }
    if (/android/i.test(userAgent)) {
      this.isAndroid = true;
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window['MSStream']) {
      this.isiPhone = true;
    }
  }

}
