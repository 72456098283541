import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/shared/services/app.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { QuizService } from 'src/app/shared/services/quiz.service';
import { LocalStorageService, STORAGE_CONSTANTS } from 'src/app/shared/services/storage.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UserService } from 'src/app/shared/services/user.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from "src/environments/environment";
import { Logger } from 'src/app/shared/utils/log-util';
import { PhoneUtil } from 'src/app/shared/utils/phone-utils';
import { FormControlService } from 'src/app/shared/services/form-control.service';
import { UikitFormComponent } from 'src/app/components/uikit-form/uikit-form.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VerifyPhoneComponent } from '../verify-phone/verify-phone.component';
import { finalize, lastValueFrom } from 'rxjs';
import { IConvoCard } from 'src/app/components/q-convo/q-convo-card/q-convo-card.component';
import { OTP_INTENTS } from 'src/app/app.const';

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss']
})
export class SignupQuestionaireComponent implements OnInit, AfterViewInit {
    utmCampaign: any;
    isSubmittedOnce: boolean;
    isPremium: boolean = false;
    queryParams: any;
    @ViewChild(UikitFormComponent) uikitFormComponent: UikitFormComponent;
    convoForm: FormGroup;
    waev: any;
    convoCards: any = [{}, {}, {}, {}];
    cardData: any = [];
    loading = false;
    convoCardsLoading = true;
    isMobile = false;
    countryCode = PhoneUtil.getCountryCode();
    constructor(
        protected fb: FormBuilder,
        protected userService: UserService,
        private authService: AuthService,
        private toastService: ToastService,
        private route: ActivatedRoute,
        private quizService: QuizService,
        protected storageService: LocalStorageService,
        protected appService: AppService,
        private controlService: FormControlService,
        private readonly ngbModal: NgbModal,
        private deviceService: DeviceDetectorService,
        private cd: ChangeDetectorRef
    ) { 
        this.route.queryParams.subscribe((params: any) => {
           this.isPremium = params.is_premium
            // Init query params if any
            this.queryParams = {
                name: params.first,
                phone_number: params.phone
            }
        });
        this.isMobile = this.deviceService.isMobile();
    }

    ngOnInit(): void {
        this.loading = true;
        this.appService.getConvoCardsData()
        .pipe(finalize(() => {
            this.convoCardsLoading = false;
            this.loading = false;
        }))
        .subscribe( (res: any) => {
            this.convoCards = res?.convos;
            this.convoCards.map((card:IConvoCard)=>{
                if (card.default_selected || (card.is_premium && this.isPremium)){
                    card.selected = true;
                }
                return card;                
            });
            this.onUpdateConvoCards({convoCards: this.convoCards});
            this.cd.detectChanges();
        });
        this.initializeConvoForm();
    }

    ngAfterViewInit(): void {
        if(window['Waev']) {
            this.waev = new window['Waev'](environment.WAEV_FORM_KEY, environment.WAEV_API_KEY);
            Logger.debug(`Waev instance created...`, window['waev']);
        }
    }

    // TODO
    postSignup(data: any) {
        this.waev?.record(data, this.deviceService.getDeviceInfo())
        .then((resp: any) => {
            Logger.debug(JSON.stringify(resp));
        })
        .catch((err: any) => {
            Logger.error(JSON.stringify(err));
        });
    }

    initializeConvoForm() {
        const userMeta = this.storageService.popFromAppCache(STORAGE_CONSTANTS.USER_META, this.queryParams);
        const { name, first_name, agree_to_terms, phone_number } = userMeta;
        this.convoForm = this.fb.group({
            name: this.controlService.getNameControl(name || first_name),
            agree_to_terms: this.controlService.getTermsControl(agree_to_terms),
            phone_number: this.controlService.getPhoneControl(PhoneUtil.withoutCountryCode(phone_number)),
            convos: this.controlService.getFormControl(null, [Validators.required])
        });
    }

    onUpdateConvoCards({convoCards}: any) {
       const selectedConvos = convoCards.filter((card: IConvoCard) => card.selected);
       this.convoForm.patchValue({convos: selectedConvos?.length ? selectedConvos : null});
    }

    validatePhoneNumber() {
        this.isSubmittedOnce = true;
        if(this.convoForm?.valid) {
            const formData = this.convoForm.getRawValue();
            this.createUserWithConvo(
                {
                    ...formData,
                    phone_number: PhoneUtil.withCountryCode(formData.phone_number)
                }, 
                (formData: any, params: any) => {
                    formData.convos = formData.convos.map(({convo_id,is_premium}: any) => {
                        return {
                            convo_id,
                            is_premium
                        }
                    });
                    return lastValueFrom(this.userService.createUserWithConvo(formData, params));
                }
            );
        }
    }

    async createUserWithConvo(formData, handlerFunc: Function) {
        this.isSubmittedOnce = true;
        this.authService.logOut();
        this.loading = true;
            try {
                const { request_token_id } = await handlerFunc(formData, this.route.snapshot.queryParams);
                this.isSubmittedOnce = false;
                this.storageService.storeInSession(STORAGE_CONSTANTS.OTP_INTENT, OTP_INTENTS.SIGNUP);
                this.storageService.storeInSession(STORAGE_CONSTANTS.REQUEST_TOKEN_ID, request_token_id);
                this.storageService.storeInSession(STORAGE_CONSTANTS.PHONE_NUMBER, formData.phone_number);
                this.storageService.storeInAppCache(STORAGE_CONSTANTS.USER_META, formData);
                this.storageService.storeInAppCache(STORAGE_CONSTANTS.JUST_SIGNEDUP_EVENT, 'true');
                this.storageService.remove(STORAGE_CONSTANTS.ALLOW_BACK_BUTTON);
                this.storageService.storeInSession(STORAGE_CONSTANTS.OTP_INTENT, OTP_INTENTS.SIGNUP);
                this.quizService.clearAll();
                this.openPhoneVerificationPopup(request_token_id);
            } catch (error) {
                this.toastService.error(error);
            } finally {
                this.loading = false;
            }
    }

    openPhoneVerificationPopup(token: string) {
        if(token) {
            const modalRef = this.ngbModal.open(VerifyPhoneComponent, {
                centered: true,
                scrollable: true,
                keyboard: false,
                size: 'lg',
                backdrop: 'static',
                windowClass:'convo-form-modal'
            });
            modalRef.componentInstance.verifyForm = this.convoForm;
            modalRef.componentInstance.onSuccess = () => {
                modalRef.close();
                this.loading = true;
            }
            modalRef.componentInstance.onError = () => {
                modalRef.close();
            }
        }
    }

    toggleTerms(){
        this.convoForm.patchValue({ agree_to_terms: !this.convoControls['agree_to_terms'].value })
    }

    get convoControls() { return this.convoForm.controls; }
}
