import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppService } from './app.service';
import { ToastService } from './toast.service';
import { BehaviorSubject } from 'rxjs';
import { QMenuItem } from 'src/app/components/navbar/navbar.config';

@Injectable({
  providedIn: 'root'
})
export class SummaryService {
  
  baseUrl: string;
  role: string;
  summary$$ = new BehaviorSubject<any|null>(null);

  constructor(
    private http: HttpClient,
    private appService: AppService,
    private toastService: ToastService
  ) { 
    this.baseUrl = `${this.appService.getAppBaseUrl()}/summary`;
  }

  refreshSummary(){
    this.summary(this.role);
  }

  summary(role: string){
    this.role = role;
    this.fetchSummary(role).subscribe({
      next: (resp: any) => {
        this.summary$$.next(resp);
      },
      error: error => {
        this.toastService.error(error);
      }
    })
  }

  publishSummaryUpdates(summary: string){
    this.summary$$.next(summary);
  }

  fetchSummary(role: string){
    return this.http.get(`${this.baseUrl}?dashboard=${role}`);
  }

  handleSummaryUpdates(summary: any, menuItems: QMenuItem[]){
    if(!summary)return;

    if(summary.unresolved_grievances || summary.unfulfilled_requests){
      const menuItem = this.findMenu(menuItems, 'support');
      const grivances = this.findMenu(menuItem?.subItems, 'Grievances');
      const requests = this.findMenu(menuItem?.subItems, 'requests');

      menuItem.badge = {
        label: (summary.unresolved_grievances || 0) + (summary.unfulfilled_requests || 0)
      };
      grivances.badge = {
        label: summary.unresolved_grievances || 0
      };
      requests.badge = {
        label: summary.unfulfilled_requests || 0
      };
    }
  }

  findMenu(menuItems: QMenuItem[], menuId: string): QMenuItem {
    return menuItems.find(mi => mi.id === menuId);
  }
}
